<template>
  <div>
    <vx-card claass="p-0" :is-refresh="isLoading">
      <div style="min-height: 195px">
        <div class="w-full h-full flex flex-col">
          <div class="flex-1">
            <div class="flex items-center flex-row space-x-4">
              <feather-icon icon="HomeIcon" class="p-3 inline-flex rounded-full text-success mb-4" :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
              <ProyekList class="mb-4" @proyekRefreshed="params.id_proyek = $event; getData();" />
            </div>
            <div class="truncate">
              <h2 class="mb-1 font-bold">{{ totalUnit('TOTAL_UNIT_ALL') | idr(0) }}</h2>
              <span>Total Unit</span>
            </div>
          </div>
          <div class="flex-1 flex flex-col border border-solid border-b-0 border-l-0 border-r-0 d-theme-border-grey-light mt-3">
            <div class="flex-1 border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light p-1">
              <div class="flex items-center justify-between space-x-1">
                <p class="text-xs">This week</p>
                <p class="text-xs font-semibold" :class="{'text-success': totalUnit('TOTAL_UNIT_THIS_WEEK') > 0}">+{{ totalUnit('TOTAL_UNIT_THIS_WEEK') | idr(0) }}</p>
              </div>
            </div>
            <div class="flex-1 border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light p-1">
              <div class="flex items-center justify-between space-x-1">
                <p class="text-xs">This month</p>
                <p class="text-xs font-semibold" :class="{'text-success': totalUnit('TOTAL_UNIT_THIS_MONTH') > 0}">+{{ totalUnit('TOTAL_UNIT_THIS_MONTH') | idr(0) }}</p>
              </div>
            </div>
            <div class="flex-1 border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light p-1">
              <div class="flex items-center justify-between space-x-1">
                <p class="text-xs">This year</p>
                <p class="text-xs font-semibold" :class="{'text-success': totalUnit('TOTAL_UNIT_THIS_YEAR') > 0}">+{{ totalUnit('TOTAL_UNIT_THIS_YEAR') | idr(0) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import ProyekList from '@/views/pages/dashboard/ProyekList'
import _ from 'lodash'

export default {
  name: 'TotalUnit',
  components: {
    ProyekList
  },
  mounted () {
    this.initData()
  },
  data () {
    return {
      isLoading: false,
      params: {
        id_proyek: null
      },
      data: {
        queryResult: []
      }
    }
  },
  methods: {
    initData () {
      this.getData()
    },

    getData () {
      this.isLoading = true

      const reportId = 70
      const params = { params: this.params }
      return ReportRepository.templateData(reportId, params)
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    totalUnit (name) {
      const record = _.find(this.data.queryResult, item => item.name === name)
      return record ? record.total : 0
    }
  }
}
</script>
